import axios from "axios";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getRoles = async (token: string) => {
  try {
    const res = await request.get('/role/get', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.roles;

    return data;
  } catch (error) {
    throw error;
  }
}

export const getVehicles = async (token: string) => {
  try {
    const res = await request.get('/vehicle/get', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.vehicles;

    return data;
  } catch (error) {
    throw error;
  }
}

export const getRequisitons = async (token: string, page: number = 1, pageSize: number = 20) => {
  try {
    const res = await request.get(`/requisition/get?page=${page - 1}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data;

    return data;
  } catch (error) {
    throw error;
  }
}

export const getUsers = async (token: string, page: number = 1, pageSize: number = 20) => {
  try {
    const res = await request.get(`/user/get?page=${page - 1}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data;

    return data;
  } catch (error) {
    throw error;
  }
}

export const getPumps = async (token: string) => {
  try {
    const res = await request.get('/pump/get', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data;

    return data;
  } catch (error) {
    throw error;
  }
}

export const getDepartments = async (token: string) => {
  try {
    const res = await request.get('/department/get', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.departments;

    return data;
  } catch (error) {
    throw error;
  }
}

export const getLocations = async (token: string) => {
  try {
    const res = await request.get('/location/get', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.locations;

    return data;
  } catch (error) {
    throw error;
  }
}

export const approveRequisition = async (token: string, id: string) => {
  try {
    const res = await request.post(`/requisition/approve/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.msg;
    return data;
  } catch (error) {
    throw error;
  }
}

export const disburseRequisition = async (token: string, id: string) => {
  try {
    const res = await request.post(`/requisition/disburse/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.msg;
    return data;
  } catch (error) {
    throw error;
  }
}

export const rejectRequisition = async (token: string, id: string, payload: string) => {
  try {
    const res = await request.post(`/requisition/reject/${id}`, {
      notes: payload
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.msg;
    return data;
  } catch (error) {
    throw error;
  }
}

export const downloadReciept = async (token: string, id: string) => {
  const res = await request.get(`/requisition/download/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob'
  });

  return res.data;
}

export const downloadPDFReport = async (token: string) => {
  const res = await request.get(`/requisition/report`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob'
  });

  return res.data;
}

export const downloadCSVReport = async (token: string, startDate: Date, endDate: Date) => {
  const res = await request.post(`/requisition/csv-report`, {
    startDate,
    endDate
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob'
  });

  return res.data;
}

export const updateFCMToken = async (token: string, fcmToken: string) => {
  try {
    const res = await request.post(`/user/update-token`, {
      token: fcmToken
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.msg;
    return data;
  } catch (error) {
    if (process.env.NODE_ENV === 'development')
      console.log(error);
  }
}

export const editPump = async (token: string, payload: {
  location: string,
  id: string,
  litres: string
}) => {
  try {
    const res = await request.post(`/pump/update`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = res.data.msg;
    return data;
  } catch (error) {
    throw error;
  }
}